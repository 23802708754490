<template>
    <div class="eCommerce-container">
        <div class="btn-top">
            <el-button type="primary" @click="submitData">提交清洗数据</el-button>
            <input type="file" ref="excelInput" accept=".xls, .xlsx" title="" @change="changeExcel($event)" style="display: none;">
        </div>
        <el-scrollbar class="eCommerce-main">
            <div class="line-1">
                <div class="data-item" style="width: 40%">
                    <div class="tab-title" v-if="infoDetail.hasOwnProperty('sex')">
                        <el-popover placement="right" trigger="hover" popper-class="customPopover">
                            <div v-if="infoDetail.sex.max === '男' || infoDetail.sex.max === '女'">
                                您的新增粉丝主要集中在 <span style="color: #ff0000;">{{infoDetail.sex.max}}性客户最多</span>
                            </div>
                            <div v-else>
                                您的新增粉丝主要集中在 <span style="color: #ff0000;">男女比例一样</span>
                            </div>
                            <div slot="reference" class="popover-title">性别</div>
                        </el-popover>
                    </div>
                    <template v-if="Object.keys(this.infoDetail).length > 0">
                        <div class="data-content" id="sexData"></div>
                    </template>
                    <div class="no-data" v-else>
                        <img class="no-img" :src="require('../../../../assets/images/student/freight-null-data.png')" alt="">
                        <p class="text">请先下载新增粉丝和成交粉丝画像数据进行清洗并提交答案，暂无法生成画像</p>
                    </div>
                </div>
                <div class="data-item" style="width: 1%; flex: 1; margin-left: 30px">
                    <div class="tab-title" v-if="infoDetail.hasOwnProperty('age')">
                        <el-popover placement="right" trigger="hover" popper-class="customPopover">
                            <div>
                                您的新增粉丝主要集中在 <span style="color: #ff0000;">{{infoDetail.age.max}}</span>
                            </div>
                            <div slot="reference" class="popover-title">年龄</div>
                        </el-popover>
                    </div>
                    <template v-if="Object.keys(this.infoDetail).length > 0">
                        <div class="data-content" id="ageData"></div>
                    </template>
                    <div class="no-data" v-else>
                        <img class="no-img" :src="require('../../../../assets/images/student/freight-null-data.png')" alt="">
                        <p class="text">请先下载新增粉丝和成交粉丝画像数据进行清洗并提交答案，暂无法生成画像</p>
                    </div>
                </div>
            </div>
            <div class="data-item" style="margin: 20px;">
                <div class="tab-title" v-if="infoDetail.hasOwnProperty('area')">
                    <el-popover placement="right" trigger="hover" popper-class="customPopover">
                        <div>
                            您的新增粉丝主要集中在 <span style="color: #ff0000;">{{infoDetail.area.max}}</span>
                        </div>
                        <div slot="reference" class="popover-title">地区</div>
                    </el-popover>
                </div>
                <template v-if="Object.keys(this.infoDetail).length > 0">
                    <el-table :data="infoDetail.area.data.slice((currentPage - 1) * 10, currentPage * 10)" class="customTable" style="width: 100%; margin-top: 30px;"
                              :row-class-name="tableRowClassName" :header-cell-style="{height: '40px'}">
                        <el-table-column type="index" label="排名" width="100" align="center">
                            <template slot-scope="scope">
                                <div class="sort-img" v-if="scope.$index < 3">
                                    <img v-if="scope.$index === 0" :src="require('../../../../assets/images/student/num-01.png')" alt="">
                                    <img v-else-if="scope.$index === 1" :src="require('../../../../assets/images/student/num-02.png')" alt="">
                                    <img v-else :src="require('../../../../assets/images/student/num-03.png')" alt="">
                                </div>
                                <div class="sort-num" v-else-if="scope.$index < 9">0{{scope.$index + 1}}</div>
                                <div class="sort-num" v-else>{{scope.$index + 1}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="地区" align="center"></el-table-column>
                        <el-table-column label="成交占比">
                            <template slot-scope="scope">
                                <div class="flex deal-box">
                                    <span>{{scope.row.value}}%</span>
                                    <el-progress :percentage="scope.row.value / areaMax * 100" color="#2DC079" :show-text="false"></el-progress>
                                </div>
                            </template>
                        </el-table-column>
                        <template slot="empty">
                            <div class="null-data" style="margin-top: 20px">
                                <div style="display: flex;flex-direction: column;align-items: center;">
                                    <img src="../../../../assets/images/student/goods-null-data.png" alt="">
                                    <span>暂无数据</span>
                                </div>
                            </div>
                        </template>
                    </el-table>
                    <div style="display: flex; justify-content: space-between; margin-top: 20px">
                        <span></span>
                        <el-pagination :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next" :total="infoDetail.area.data.length"></el-pagination>
                    </div>
                </template>
                <div class="no-data" v-else>
                    <img class="no-img" :src="require('../../../../assets/images/student/freight-null-data.png')" alt="">
                    <p class="text">请先下载新增粉丝和成交粉丝画像数据进行清洗并提交答案，暂无法生成画像</p>
                </div>
            </div>
            <div class="line-1">
                <div class="data-item" style="width: calc(50% - 15px); flex: 1;">
                    <div class="tab-title" v-if="infoDetail.hasOwnProperty('level')">
                        <el-popover placement="right" trigger="hover" popper-class="customPopover">
                            <div>
                                您的新增粉丝中 <span style="color: #ff0000;">{{infoDetail.level.max}}</span>
                            </div>
                            <div slot="reference" class="popover-title">会员等级</div>
                        </el-popover>
                    </div>
                    <template v-if="Object.keys(this.infoDetail).length > 0">
                        <div class="data-content" id="gradeData"></div>
                    </template>
                    <div class="no-data" v-else>
                        <img class="no-img" :src="require('../../../../assets/images/student/freight-null-data.png')" alt="">
                        <p class="text">请先下载新增粉丝和成交粉丝画像数据进行清洗并提交答案，暂无法生成画像</p>
                    </div>
                </div>
                <div class="data-item" style="width: calc(50% - 15px); flex: 1; margin-left: 30px">
                    <div class="tab-title" v-if="infoDetail.hasOwnProperty('power')">
                        <el-popover placement="right" trigger="hover" popper-class="customPopover">
                            <div>
                                您的新增粉丝中 <span style="color: #ff0000;">{{infoDetail.power.max}}</span>
                            </div>
                            <div slot="reference" class="popover-title">购买力</div>
                        </el-popover>
                    </div>
                    <template v-if="Object.keys(this.infoDetail).length > 0">
                        <div class="data-content" id="buyData"></div>
                    </template>
                    <div class="no-data" v-else>
                        <img class="no-img" :src="require('../../../../assets/images/student/freight-null-data.png')" alt="">
                        <p class="text">请先下载新增粉丝和成交粉丝画像数据进行清洗并提交答案，暂无法生成画像</p>
                    </div>
                </div>
            </div>
            <div class="line-1" style="margin-bottom: 70px">
                <div class="data-item" style="width: calc(50% - 15px); flex: 1;">
                    <div class="tab-title" v-if="infoDetail.hasOwnProperty('promotion')">
                        <el-popover placement="right" trigger="hover" popper-class="customPopover">
                            <div>
                                您的新增粉丝对促销信息 <span style="color: #ff0000;">{{infoDetail.promotion.max}}</span>
                            </div>
                            <div slot="reference" class="popover-title">促销敏感度</div>
                        </el-popover>
                    </div>
                    <template v-if="Object.keys(this.infoDetail).length > 0">
                        <div class="data-content" id="promoteData"></div>
                    </template>
                    <div class="no-data" v-else>
                        <img class="no-img" :src="require('../../../../assets/images/student/freight-null-data.png')" alt="">
                        <p class="text">请先下载新增粉丝和成交粉丝画像数据进行清洗并提交答案，暂无法生成画像</p>
                    </div>
                </div>
                <div class="data-item" style="width: calc(50% - 15px); flex: 1; margin-left: 30px">
                    <div class="tab-title" v-if="infoDetail.hasOwnProperty('comment')">
                        <el-popover placement="right" trigger="hover" popper-class="customPopover">
                            <div>
                                您的新增粉丝对评论 <span style="color: #ff0000;">{{infoDetail.comment.max}}</span>
                            </div>
                            <div slot="reference" class="popover-title">评论敏感度</div>
                        </el-popover>
                    </div>
                    <template v-if="Object.keys(this.infoDetail).length > 0">
                        <div class="data-content" id="commentData"></div>
                    </template>
                    <div class="no-data" v-else>
                        <img class="no-img" :src="require('../../../../assets/images/student/freight-null-data.png')" alt="">
                        <p class="text">请先下载新增粉丝和成交粉丝画像数据进行清洗并提交答案，暂无法生成画像</p>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import { cleanUpload, clean_drillUpload, cleanAdd, clean_drillAdd } from '@/utils/apis.js'
    import { mapState, mapActions } from "vuex";
    export default {
        data() {
            return {
                infoDetail: {},
                sexData: null,
                ageData: null,
                gradeData: null,
                buyData: null,
                promoteData: null,
                commentData: null,
                areaMax:null,
                currentPage: 1,
            }
        },
        computed: {
            ...mapState({
                setStudentExamData: state => state.studentExamData
            })
        },
        mounted() {
            this.getInfo()
        },
        methods: {
            tableRowClassName({row, rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'statistics-warning-row';
                } else {
                    return '';
                }
            },
            // 提交数据
            submitData() {
                this.$refs.excelInput.click();
            },
            // 选择文件
            changeExcel(event) {
                if (event.target.files.length === 0) return;
                let fileList = event.target.files[0];
                let formData = new FormData();
                formData.append('file', fileList);
                if (this.$route.query.op_id) {
                    formData.append('id', this.$route.query.op_id);
                    formData.append('course_id', this.$route.query.course_id);
                    formData.append('chapter', this.$route.query.chapter);
                    formData.append('node', this.$route.query.node);
                    clean_drillUpload(formData).then((res) => {
                        this.$message.success(res.msg)
                        this.getInfo()
                    }).catch((err) => {
                        // console.log(err)
                    }).finally(() => {
                        this.$refs.excelInput.value = ''
                    })
                } else {
                    cleanUpload(formData).then((res) => {
                        this.$message.success(res.msg)
                        this.getInfo()
                    }).catch((err) => {
                        // console.log(err)
                    }).finally(() => {
                        this.$refs.excelInput.value = ''
                    })
                }
            },
            // 获取详情
            async getInfo() {
                let params = {
                    type: 3
                }
                if (this.$route.query.op_id) {
                    params.id = Number(this.$route.query.op_id)
                    params.course_id = Number(this.$route.query.course_id)
                    params.chapter = Number(this.$route.query.chapter)
                    params.node = Number(this.$route.query.node)
                }
                let res
                if (this.$route.query.op_id) {
                    res = await clean_drillAdd(params)
                } else {
                    res = await cleanAdd(params)
                }
                if (res.data.length === 0) {
                    this.infoDetail = {}
                } else {
                    let areaArr = []
                    for (const key in res.data.area.data) {
                        let tmp = {
                            name: key,
                            value: res.data.area.data[key]
                        }
                        areaArr.push(tmp)
                    }
                    areaArr = areaArr.sort((a, b) => {
                        return b.value-a.value;
                    });
                    if(areaArr && areaArr.length) this.areaMax = areaArr[0].value;
                    this.infoDetail = {
                        sex: {
                            max: res.data.sex.max,
                            data: this.formatSexData(res.data.sex.data)
                        },
                        age: {
                            max: res.data.age.max,
                            data: this.formatData(res.data.age.data)
                        },
                        area: {
                            max: res.data.area.max,
                            data: areaArr,
                        },
                        level: {
                            max: res.data.level.max,
                            data: this.formatData(res.data.level.data)
                        },
                        power: {
                            max: res.data.power.max,
                            data: this.formatData(res.data.power.data)
                        },
                        promotion: {
                            max: res.data.promotion.max,
                            data: this.formatData(res.data.promotion.data)
                        },
                        comment: {
                            max: res.data.comment.max,
                            data: this.formatData(res.data.comment.data)
                        },
                    }
                    if (Object.keys(this.infoDetail).length > 0) {
                        this.$nextTick(() => {
                            this.sexData = this.$echarts.init(document.getElementById('sexData'));
                            this.ageData = this.$echarts.init(document.getElementById('ageData'));
                            this.gradeData = this.$echarts.init(document.getElementById('gradeData'));
                            this.buyData = this.$echarts.init(document.getElementById('buyData'));
                            this.promoteData = this.$echarts.init(document.getElementById('promoteData'));
                            this.commentData = this.$echarts.init(document.getElementById('commentData'));

                            let resizeTimer = null;
                            window.onresize = () => {
                                clearTimeout(resizeTimer);
                                resizeTimer = setTimeout(() => {
                                    this.sexData.resize()
                                    this.ageData.resize()
                                    this.gradeData.resize()
                                    this.buyData.resize()
                                    this.promoteData.resize()
                                    this.commentData.resize()
                                }, 100)
                            }
                            this.setSexOption()
                            this.GetAgeData()
                            this.GetGradeData()
                            this.GetBuyData()
                            this.GetPromoteData()
                            this.GetCommentData()
                        })
                    }
                }
            },
            // 处理数据
            formatData(data) {
                let obj = {
                    xData: [],
                    yData: []
                }
                for (const key in data) {
                    obj.xData.push(key)
                    obj.yData.push(data[key])
                }
                return obj
            },
            // 性别数据处理
            formatSexData(data) {
                let arr = []
                for (const key in data) {
                    let tmp = {
                        value: data[key],
                        name: key === 'man' ? '男性' : '女性'
                    }
                    arr.push(tmp)
                }
                return arr
            },
            // 性别环形 eCharts
            setSexOption() {
                // if (this.infoDetail.hasOwnProperty('sex')) {
                this.sexData.setOption({
                    legend: {
                        bottom: '-2%',
                        left: 'center',
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985',
                            }
                        },
                        formatter: '{a}<br />{b} : {c}%',
                    },
                    grid: {
                        top: 20,
                        left: 0,
                        right: 0,
                        bottom: 100,
                    },
                    series: [{
                        name: '性别',
                        type: 'pie',
                        radius: ['55%', '70%'],
                        data: this.infoDetail.sex.data,
                        barWidth: 18,
                        label: {
                            show: true,
                            position: 'top',
                            color: '#666',
                            formatter: '{b}:{c}%'
                        },
                        itemStyle: {
                            borderRadius: 100,
                            borderColor: '#ff0000',
                            borderWidth: 100,
                            normal: {
                                color: (params) => {
                                    let colorList = [
                                        ['#F9727E', '#FDAE95'],
                                        ['#32C17A', '#B9E5CA'],
                                    ]
                                    let index = params.dataIndex;
                                    if (params.dataIndex >= colorList.length) {
                                        index = params.dataIndex - colorList.length;
                                    }
                                    return new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                        { offset: 0, color: colorList[index][0] },
                                        { offset: 1, color: colorList[index][1] }
                                    ])
                                },
                            },
                        },
                    }]
                });
            },
            // 柱状图 eCharts
            setEChartsOption(item, xData, yData, title, colorBottom, colorTop) {
                item.setOption({
                    xAxis: {
                        type: 'category',
                        data: xData,
                        axisLabel: {
                            textStyle: {
                                color: '#666'
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            textStyle: {
                                color: '#9A9A9A'
                            },
                            formatter: '{value} %'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985',
                            }
                        },
                        formatter: '{a}<br />{b} : {c}%',
                    },
                    grid: {
                        top: 20,
                        left: 50,
                        right: 0,
                        bottom: 25,
                    },
                    series: [{
                        name: title,
                        type: 'bar',
                        data: yData,
                        barWidth: 18,
                        label: {
                            show: true,
                            position: 'top',
                            color: '#666',
                            formatter: '{c}%'
                        },
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                    {
                                        offset: 0,
                                        color: colorBottom, // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: colorTop, // 100% 处的颜色
                                    }
                                ], false)
                            },
                        },
                    }]
                });
            },
            // 柱状图——年龄
            GetAgeData() {
                let xData = this.infoDetail.age.data.xData
                let yData = this.infoDetail.age.data.yData
                this.setEChartsOption(this.ageData, xData, yData, '年龄', '#428AF5', '#60C6FF')
            },
            // 柱状图——会员等级
            GetGradeData() {
                let xData = this.infoDetail.level.data.xData
                let yData = this.infoDetail.level.data.yData
                this.setEChartsOption(this.gradeData, xData, yData, '会员等级', '#FE9B1C', '#FFD83B')
            },
            // 柱状图——购买力
            GetBuyData() {
                let xData = this.infoDetail.power.data.xData
                let yData = this.infoDetail.power.data.yData
                this.setEChartsOption(this.buyData, xData, yData, '购买力', '#3BC37F', '#ADE2C3')
            },
            // 柱状图——促销敏感度
            GetPromoteData() {
                let xData = this.infoDetail.promotion.data.xData
                let yData = this.infoDetail.promotion.data.yData
                this.setEChartsOption(this.promoteData, xData, yData, '促销敏感度', '#F9757F', '#FDA198')
            },
            // 柱状图——评论敏感度
            GetCommentData() {
                let xData = this.infoDetail.comment.data.xData
                let yData = this.infoDetail.comment.data.yData
                this.setEChartsOption(this.commentData, xData, yData, '评论敏感度', '#428AF5', '#60C6FF')
            },
        }
    }
</script>

<style scoped lang="scss">
    .eCommerce-container {
        height: 100%;
        position: relative;
        .eCommerce-main {
            height: 100%;
            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .line-1 {
                display: flex;
                margin: 20px;
            }
        }
    }
    .btn-top {
        position: absolute;
        right: 20px;
        top: -47px;
    }
    .data-item {
        padding: 34px 20px 40px;
        box-shadow: 0 0 8px 2px rgba(0, 0, 0, .05);
        border-radius: 10px;
        background: #fff;
    }
    .tab-title {
        padding-left: 15px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: -2px;
            left: 0;
            width: 5px;
            height: 20px;
            background: #2DC079;
            border-radius: 3px;
        }
        .popover-title {
            font-size: 18px;
            line-height: 1;
            display: inline-block;
        }
    }
    .data-content {
        height: 300px;
        width: 100%;
        margin-top: 30px;
    }
    .sort-num {
        display: inline-block;
        background: #E7F6EF;
        height: 24px;
        width: 24px;
        border-radius: 12px;
        line-height: 24px;
        text-align: center;
    }
    .sort-img {
        display: inline-block;
        img {
            display: block;
        }
    }
    .deal-box {
        align-items: center;
        .el-progress {
            width: 1%;
            flex: 1;
            margin-left: 15px;
            margin-right: 20px;
        }
    }
    .no-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        .no-img {
            max-width: 200px;
        }
        .text {
            color: #999;
            margin-top: 12px;
            padding: 0 20px;
        }
    }
    .el-table {
        ::v-deep .el-table__row.statistics-warning-row {
            background: #F9FFFC;
        }
    }
</style>